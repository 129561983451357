export const COLORS = {
  text: {
    light: '#33333A',
    dark: '#f2f2f2',
  },
  body: {
    light: '#f2f2f2',
    dark: '#33333A',
  },
  tools: {
    light: '#D4D4D4',
    dark: '#2C2C34',
  },
  'shadow-dark': {
    light: '#bdbdbc',
    dark: '#22222a',
  },
  'shadow-light': {
    light: '#ffffff',
    dark: '#3b3b43',
  },
};

export const COLOR_MODE_KEY = 'color-mode';
export const INITIAL_COLOR_MODE_CSS_PROP = '--initial-color-mode';
